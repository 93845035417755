<template>
    <div>
        <!-- 主体 -->
        <LiefengContent>
            <template v-slot:title>{{ "社区通知管理" }}</template>
            <!-- 头部搜索层 -->
            <template v-slot:toolsbarLeft>
              <Button style="margin: 0" :type="orgOnlineStatus == 0 ? 'primary' : ''" @click="checkStatus('')">我发布的</Button>
              <Button style="margin: 0" :type="orgOnlineStatus == 1 ? 'primary' : ''" @click="checkStatus(1)">全部</Button>
            </template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem>
                        <Input v-model="searchForm.title" maxlength="30" placeholder="请输入标题" style="width: 160px"></Input>
                    </FormItem>
                    <FormItem>
                        <LiefengCascaderCity
                            :orgCode="4401"
                            :resetNum="resetNum"
                            @changeCascaderOnce="changeCascaderOnce"
                            @changeCasader="changeCasader"
                            @changeCasaderFirst="changeCasaderFirst"
                        ></LiefengCascaderCity>
                    </FormItem>
                    <FormItem>
                        <CheckboxGroup v-model="searchForm.statusList">
                            <Checkbox label="4">已发布</Checkbox>
                            <Checkbox label="5">已过期</Checkbox>
                        </CheckboxGroup>
                        <!-- <Checkbox v-model="searchForm.recommend" true-value="1" false-value="">推荐</Checkbox> -->
                        <Checkbox v-model="searchForm.position" true-value="1" false-value="">通知栏</Checkbox>
                        <Checkbox v-model="searchForm.hidden" true-value="0" false-value="">隐藏</Checkbox>
                        <Checkbox v-model="searchForm.top" true-value="1" false-value="">置顶</Checkbox>
                    </FormItem>
                    <Button style="margin-right: 10px" type="primary" icon="ios-search" @click="searchBtn">查询</Button>
                    <Button style="margin-right: 10px" type="success" @click="resetBtn" icon="ios-refresh">重置</Button>
                </Form>
            </template>
            <!-- 分页层 -->
            <template v-slot:contentArea>
                <!-- 分页table -->
                <LiefengTable
                    :talbeColumns="pageForm.talbeColumns"
                    :tableData="pageForm.tableData"
                    :loading="controlForm.loading"
                    :fixTable="true"
                    :curPage="pageForm.page"
                    :total="pageForm.total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageForm.pageSize"
                    @hadlePageSize="changeTableSize"
                ></LiefengTable>
                <!-- <Form ref="Form" @resetBtn="resetBtn" @getAllFunction="getChildrenList"></Form> -->

                <pushModal
                    @changePushStastu="changePushStastu"
                    :businessType="pushModal.businessType"
                    :functionType="pushModal.functionType"
                    :informationId="pushModal.informationId"
                    :modalStatus="pushModal.modalStatus"
                ></pushModal>
            </template>
            <!-- 新增modal -->
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import pushModal from "../activity/childrens/pushModal"

export default {
    components: { LiefengContent, LiefengTable, LiefengModal, LiefengCascaderCity, pushModal },
    data() {
        return {
            //分页参数
            pageForm: {
                talbeColumns: [
                    {
                        title: "所属栏目",
                        key: "columnName",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "Button",
                                    {
                                        style: {
                                            marginRight: "20px",
                                            display: params.row.functionType && params.row.functionType != "" ? "block" : "none",
                                        },
                                        props: {
                                            type: "info",
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.$router.push(`${this.$core.funToPage(params.row)}`)
                                            },
                                        },
                                    },
                                    params.row.columnName
                                ),
                            ])
                        },
                    },
                    {
                        title: "信息标题",
                        key: "title",
                        minWidth: 300,
                        align: "center",
                    },
                    // {
                    //     title: "最新推荐",
                    //     minWidth: 100,
                    //     align: "center",
                    //     render: (h, params) => {
                    //         return h("checkbox", {
                    //             props: {
                    //                 disabled: params.row.status == 5 ? true : false,
                    //                 value: params.row.recommend, //这里的value值对应Radio中的label中的值
                    //                 trueValue: 1,
                    //                 falseValue: 0,
                    //             },
                    //             on: {
                    //                 "on-change": async res => {
                    //                     let data = {
                    //                         informationId: params.row.informationId,
                    //                         recommend: res,
                    //                     }
                    //                     await this.changeStatus(data)
                    //                 },
                    //             },
                    //         })
                    //     },
                    // },
                    {
                        title: "通知栏",
                        minWidth: 100,
                        align: "center",
                        render: (h, params) => {
                            return h("checkbox", {
                                props: {
                                    disabled: params.row.status == 5 ? true : false,
                                    value: params.row.position, //这里的value值对应Radio中的label中的值
                                    trueValue: 1,
                                    falseValue: 0,
                                },
                                on: {
                                    "on-change": async res => {
                                        let data = {
                                            informationId: params.row.informationId,
                                            position: res,
                                        }
                                        await this.changeStatus(data)
                                    },
                                },
                            })
                        },
                    },
                    {
                        title: "发布范围",
                        key: "pubScope",
                        minWidth: 200,
                        align: "center",
                    },
                    {
                        title: "发布状态",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "div",
                                {},
                                params.row.status
                                    ? params.row.status == "1"
                                        ? "待审核"
                                        : params.row.status == "2"
                                        ? "已驳回"
                                        : params.row.status == "3"
                                        ? "待发布"
                                        : params.row.status == "4"
                                        ? "已发布"
                                        : params.row.status == "5"
                                        ? "已过期"
                                        : params.row.status == "6"
                                        ? "已归档"
                                        : ""
                                    : ""
                            )
                        },
                    },
                    {
                        title: "显示隐藏",
                        key: "hidden",
                        minWidth: 200,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "RadioGroup",
                                {
                                    props: {
                                        value: params.row.hidden, //这里的value值对应Radio中的label中的值
                                    },
                                    on: {
                                        "on-change": async res => {
                                            let data = {
                                                informationId: params.row.informationId,
                                                hidden: res,
                                            }
                                            await this.changeStatus(data)
                                        },
                                    },
                                },
                                [
                                    h(
                                        "Radio",
                                        {
                                            props: {
                                                label: 1,
                                            },
                                        },
                                        "显示"
                                    ),
                                    h(
                                        "Radio",
                                        {
                                            props: {
                                                label: 0,
                                            },
                                        },
                                        "隐藏"
                                    ),
                                ]
                            )
                        },
                    },
                    {
                        title: "置顶",
                        minWidth: 100,
                        align: "center",
                        render: (h, params) => {
                            return h("checkbox", {
                                props: {
                                    // disabled: params.row.recommend == 0 ? true : false,
                                    value: params.row.top, //这里的value值对应Radio中的label中的值
                                    trueValue: 1,
                                    falseValue: 0,
                                },
                                on: {
                                    "on-change": async res => {
                                        let data = {
                                            informationId: params.row.informationId,
                                            top: res,
                                        }
                                        await this.changeStatus(data)
                                    },
                                },
                            })
                        },
                    },
                    // {
                    //     title: "操作",
                    //     align: "center",
                    //     fixed: "right",
                    //     width: 180,
                    //     render: (h, params) => {
                    //         return h("div", [
                    //             h(
                    //                 "Button",
                    //                 {
                    //                     attrs: {
                    //                         type: "info",
                    //                         size: "small",
                    //                     },
                    //                     on: {
                    //                         click: () => {
                    //                             this.pushModal = {
                    //                                 businessType: params.row.businessType,
                    //                                 functionType: params.row.functionType,
                    //                                 informationId: params.row.informationId,
                    //                                 modalStatus: true,
                    //                             }
                    //                         },
                    //                     },
                    //                     style: {
                    //                         marginRight: "10px",
                    //                         textAlign: "center",
                    //                         display: this.buttonRoot ? "" : "none",
                    //                     },
                    //                 },
                    //                 "推送到智慧屏" //1001
                    //             ),
                    //         ])
                    //     },
                    // },
                ],
                tableData: [],
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                currentPage: 0,
            },
            //控制的一些属性
            controlForm: {
                loading: false,
            },
            //查询参数
            searchForm: {
                title: "", // 标题
                statusList: [], // 数据状态
                top: "", // 是否置顶
                hidden: "", // 显示或者隐藏
                recommend: "", // 是否推荐
                position: "", // 开放位置
            },

            // 清空选择市区街道数组
            resetNum: 0,
            // 存放查询接口的社区数组
            cascaderIdList: [],

            pushModal: {
                businessType: "",
                functionType: "",
                informationId: "",
                modalStatus: false,
            },

            buttonRoot: false, // 按钮权限

            orgOnlineStatus:0
        }
    },
    //create函数,刚加载页面时调用
    async created() {
        let userButtonRoot = parent.vue.userButtonRoot
        this.buttonRoot = userButtonRoot.includes("1001") || userButtonRoot.includes("1002") || userButtonRoot.includes("1003") ? true : false

        console.log(this.buttonRoot)
    },
    methods: {
        // 切换我发布的，全部按钮状态
        checkStatus(status){
          if(status){
            this.orgOnlineStatus = 1
            this.pageForm.page = 1
            this.selectPage('')
          }else if(!status || status == ''){
            this.orgOnlineStatus = 0
            this.pageForm.page = 1
            this.selectPage()
          }
        },
        changePushStastu(status) {
            this.pushModal = {
                businessType: "",
                functionType: "",
                informationId: "",
                modalStatus: status,
            }
        },
        // 城市组件
        changeCasader(val) {
            this.cascaderIdList = val
            if (this.resetNum == 0 && this.cascaderIdList.length == 3) {
                this.searchBtn()
            }
        },
        /*----- 分页事件部分 -------*/

        //分页事件改变
        changeTableSize(val) {
            this.pageForm.page = val.page
            this.pageForm.pageSize = val.pageSize
            this.selectPage()
        },
        //查询按钮
        searchBtn() {
            if (this.cascaderIdList.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区进行查询",
                    background: true,
                })
                return
            }
            this.pageForm.page = 1
            this.selectPage()
        },
        //重置按钮
        resetBtn() {
            this.searchForm = {
                title: "", // 标题
                statusList: [], // 数据状态
                top: "", // 是否置顶
                hidden: "", // 显示或者隐藏
                recommend: "", // 是否推荐
                position: "", // 开放位置
            }
            this.pageForm.page = 1
            this.selectPage()
        },
        // 方法部分
        /*----- 接口部分 -------*/
        async selectPage(orgCode = parent.vue.loginInfo.userinfo.orgCode) {
            this.controlForm.loading = true
            await this.$get("/info/api/pc/information/v2/queryControlByPage", {
                page: this.pageForm.page,
                pageSize: this.pageForm.pageSize,
                orgCodeSelf: orgCode,
                orgCode: this.cascaderIdList[this.cascaderIdList.length - 1],
                oemCode: parent.vue.oemInfo.oemCode,
                ...this.searchForm,
                top: this.searchForm.top == "1" ? this.cascaderIdList[this.cascaderIdList.length - 1] : "0", // 是否置顶
                // hidden:this.searchForm.hidden, // 显示或者隐藏
                recommend: this.searchForm.recommend == "1" ? this.cascaderIdList[this.cascaderIdList.length - 1] : "0", // 是否推荐
                position: this.searchForm.position == "1" ? this.cascaderIdList[this.cascaderIdList.length - 1] : "0", // 开放位置
                statusList: this.searchForm.statusList && this.searchForm.statusList.length ? this.searchForm.statusList.join(",") : "",
                terminal: "0",
                news: "0",
            }).then(res => {
                this.pageForm.tableData = []
                this.controlForm.loading = false
                if (res.code == 200) {
                    res.dataList.map(item => {
                        if (item.hidden && item.hidden > 0) item.hidden = 1
                        if (item.position && item.position > 0) item.position = 1
                        if (item.recommend && item.recommend > 0) item.recommend = 1
                        if (item.top && item.top > 0) item.top = 1
                    })
                    let _this = this
                    setTimeout(() => {
                        _this.pageForm.tableData = res.dataList
                    }, 10)
                    this.pageForm.total = res.maxCount
                    this.pageForm.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取列表失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 修改当前数据状态
        async changeStatus(data) {
            let res = await this.$post("/info/api/pc/information/v2/updateControlStatus", {
                ...data,
                orgCode: this.cascaderIdList[this.cascaderIdList.length - 1],
            })
            if (res && res.code == 200) {
                this.$Message.success({
                    content: "操作成功",
                    background: true,
                })
            } else {
                this.$Message.error({
                    content: res.desc,
                    background: true,
                })
            }
            this.pageForm.page = this.pageForm.currentPage
            this.selectPage()
        },
    },
}
</script>

<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
/deep/.ivu-form-item-content {
    display: flex;
}
</style>