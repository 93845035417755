<template>
    <!-- 关联数据 -->
    <LiefengModal title="选择推送时间" class="push-modeal" :value="relationStatus" @input="changeRelationStatus" :fullscreen="false">
        <template v-slot:contentarea>
            <span style="margin-right: 5px">推送时间:</span>
            <InputNumber :max="100000" :min="1" v-model="frequency"></InputNumber>
            <span style="margin-left: 5px">分钟</span>
            <div style="margin: 20px 0">
                <div style="margin: 20px 0">
                    <span class="validate" style="margin-right: 5px">选择设备（离线设备不可选）:</span>
                    <RadioGroup v-model="sleectCheck" @on-change="changeRadio">
                        <Radio label="全选"></Radio>
                        <Radio label="取消全选"></Radio>
                    </RadioGroup>
                </div>
                <CheckboxGroup v-model="ternailList" @on-change="changeCheckbox">
                    <template v-if="orgList && orgList.length == 1 && selectTernalList && selectTernalList.length">
                        <Checkbox v-for="(item, index) in selectTernalList" :key="index" :label="item.terminalUuid" :disabled="item.disabled">{{ item.terminalName }}</Checkbox>
                    </template>
                    <template v-if="!selectTernalList || !selectTernalList.length">
                        {{ "暂无终端或权限异常" }}
                    </template>
                </CheckboxGroup>
            </div>
        </template>
        <template v-slot:toolsbar>
            <Button style="margin-right: 10px" type="info" @click="changeRelationStatus(false)">关闭</Button>
            <Button type="primary" @click="updateSend" :loading="loading">确定</Button>
        </template>
    </LiefengModal>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
export default {
    props: ["businessType", "functionType", "informationId", "modalStatus"],
    components: {
        LiefengModal,
    },
    data() {
        return {
            relationStatus: false,
            frequency: 30,
            loading: false,
            orgList: [],
            selectTernalList: [],
            // 已选终端数组
            ternailList: [],
            sleectCheck: "",
        }
    },
    async created() {
        await this.getSelect()
        await this.getTerinal()
    },
    methods: {
        changeCheckbox(val) {
            if (val && val.length == this.ternailList.length) this.sleectCheck = "取消全选"
            else this.sleectCheck = "全选"
        },
        changeRadio(val) {
            if (val == "全选") {
                this.ternailList = []
                this.selectTernalList.map(item => {
                    if (!item.disabled) {
                        item.checked = true
                        this.ternailList.push(item.terminalUuid)
                    }
                })
            } else {
                this.ternailList = []
                this.selectTernalList.map(item => {
                    item.checked = false
                })
            }
        },
        changeRelationStatus(status) {
            if (!status) {
                this.relationStatus = status
                this.$emit("changePushStastu", this.relationStatus)
            }
        },
        // 点击确定按钮
        async updateSend() {
            await this.pushRow()
        },
        // 获取当前所有的权限社区
        async getSelect() {
            await this.$get("/gx/pc/staff/selectDatsScopeAndFilterLevel", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                filterLevel: 5,
                oemCode: parent.vue.oemInfo.oemCode,
            })
                .then(res => {
                    if (res.code === "200" && res.dataList && res.dataList.length > 0) {
                        this.orgList = res.dataList
                    } else {
                        this.orgList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 获取终端列表
        async getTerinal() {
            this.$get("/gateway/api/sysmartscreen/app/terminal/terminallist", {
                terminalGroupId: this.orgList[0].orgId,
            }).then(res => {
                if (res.code == 200) {
                    this.selectTernalList = res.dataList.map(item => {
                        return {
                            ...item,
                            disabled: !item.onlineStatus || item.onlineStatus != "2" ? true : false,
                        }
                    })
                } else {
                    this.selectTernalList = []
                    this.$Message.error({
                        content: "获取终端列表信息失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // push接口
        async pushRow() {
            if (!this.ternailList || !this.ternailList.length) {
                this.$Message.warning({
                    content: "请选择终端",
                    background: true,
                })
                return
            }
            if (this.orgList) {
                if (this.orgList.length > 1) {
                    this.$Message.warning({
                        content: "权限异常",
                        background: true,
                    })
                    return
                }
                if (this.orgList.length == 1) {
                    this.loading = true
                    await this.$post("/info/api/pc/information/polymerization/pushSmartScreen", {
                        businessType: this.businessType,
                        functionType: this.functionType,
                        frequency: this.frequency,
                        informationId: this.informationId,
                        oemCode: parent.vue.oemInfo.oemCode,
                        terminal: "2",
                        clientIds: this.ternailList.join(","),
                        orgCode: this.orgList[0].orgCode,
                    }).then(res => {
                        this.$emit("changePushStastu", false)
                        this.loading = false
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "推送成功",
                                background: true,
                            })
                            return
                        } else {
                            this.$Message.error({
                                content: "推送失败",
                                background: true,
                            })
                            return
                        }
                    })
                }
            }
        },
    },
    watch: {
        modalStatus(val) {
            this.relationStatus = val
            this.frequency = 30
            this.ternailList = []
        },
    },
}
</script>

<style lang="less" scoped>
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.push-modeal {
    /deep/ #modal_contentarea {
        height: 200px;
    }
}
</style>